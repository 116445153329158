<template>
    <div>
        <Head :title="'拆解工序'" :isPopup="true" @closePopup="$emit('closePopup')" />
        <div class="gongxulist">
            <div class="list" v-for="item in listObj.field" :key="item.id">
                <van-field  v-model="item.name" readonly label="工序名称" />
                <van-field
                    readonly
                    clickable
                    name="picker"
                    v-model="item.disassembleUserValue"
                    label="拆解人员"
                    placeholder="点击选择拆解人员"
                    type="textarea"
                    @click="UserChange(item)"
                    />
                <van-popup v-model="item.showUser" position="bottom" class="userPopup"  :close-on-click-overlay="false"  :style="{height: '100%',}">
                    <van-checkbox-group class="group" v-model="item.result">
                        <van-checkbox class="checkbox" v-for="i in principalUserList" :key="i.id" :name="i.id">{{i.realName}}</van-checkbox>
                    </van-checkbox-group>
                    <div class="userBtn">
                        <van-button @click="(item.showUser = false)">取消</van-button>
                        <van-button type="info" @click.stop="userSubmit(item)">确认</van-button>
                    </div>
                </van-popup>

                <van-field
                    readonly
                    clickable
                    name="picker"
                    v-model="item.gongwei"
                    label="工位"
                    placeholder="点击选择工位"
                    @click="stationChange(item)"
                    />
                <van-popup v-model="item.showType" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="columns"
                        value-key="name"
                        @confirm="onConfirm(1,$event,item)"
                        @cancel="item.showType = false"
                    />
                </van-popup>
                <van-field  v-model="item.remark"  label="拆解要求" placeholder="请输入拆解要求" />
            </div>
        </div>
        <div class="submitBtn">
            <van-button @click="$emit('closePopup')">取消</van-button>
            <van-button type="info" @click="submit">确定</van-button>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
import { getStation } from '@/api/publics'
    export default {
        components: {
            Head,
        },
        props:['processObj'],
        data() {
            return {
                principalUserList: [],
                columns: [],
                listObj:{}
            }
        },
        watch: {
            processObj: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    console.log(newValue)
                    let a = JSON.stringify(newValue)
                    this.listObj = JSON.parse(a)
                }
            }
        },
        // const obja = {
        //   working_procedure_id: i.id,
        //   working_procedure_station_id: i.working_procedure_id,
        //   remark: i.remark,
        //   user_json: arr
        // }
      
        methods: {
            submit(){
           
                let arr = []
                this.listObj.field.forEach(i=>{
                    console.log(i)
                    let obj = {
                        working_procedure_id: i.id,
                        working_procedure_station_id: i.working_procedure_id,
                        remark: i.remark,
                        user_json: i.user_json
                    }
                    arr.push(obj)
                })
           
                this.$emit('gongxuHan',arr)
            },
            //确认工位
            onConfirm(num,val,item){
                item.gongwei = val.name
                item.working_procedure_id = val.id
                item.showType = false
            },  
            //获取工位
            async stationChange(item){
                try{
                    const {data} = await getStation({working_procedure_id:item.id}).then(res=>res)
                    console.log(data)
                    if(data.data.length!=0){

                        this.columns = data.data
                        this.$set(item,'showType',true)
                    }else{
                        this.$toast.fail('暂无库位，请添加库位！')
                    }
                }catch{
                    this.$toast.fail('暂无库位，请添加库位！')
                }
               
            },  
            //拆解人员
            userSubmit(item){
                item.showUser = false
               
                var arr = []
                var nameArr = []
                item.result.forEach(i=>{
                    this.principalUserList.forEach(j=>{
                        if(i==j.id){
                            const obj = {
                            'realName': j.realName,
                            'id': j.id
                        }
                            arr.push(obj)
                            nameArr.push(j.realName)
                        }
                    })
                })
                item.disassembleUserValue = nameArr.join(',')
                item.user_json = arr
            },
            UserChange(i) {
                console.log(i)
                this.getUserList()
                this.$set(i,'showUser',true)
                // i.showUser = true
            },
               //获取人员
            getUserList(){
                this.$http.post('/index.php/index/user/userList',{ type: 'bulkBreaker' }).then(res=>{
                    this.principalUserList = res.data.data
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
.gongxulist{
    margin-top: 50px;
    padding: 10px;
    overflow-y: scroll;
    height: 85vh;
    border-bottom: 1px solid #eee;
    .list{
        border: 1px solid #eee;
        margin: 20px 0;
    }
    .userPopup{
        .userBtn{
            text-align: center;
            margin-top: 10px;
            .van-button{
                margin: 10px;
            }
        }
        
        .group{
            text-align: center;
            padding: 20px;
            height: 80vh;
            overflow-y: scroll;
            border-bottom: 1px solid #eee;
            .checkbox{
                margin: 5px 0;
            }
        }
    }
}
.submitBtn{
    text-align: center;
    .van-button{
        margin: 10px;
    }
}
</style>