<template>
    <div>
        <Head v-if="(isyuchuli!=1)" :title="'拆解任务分配'" :isPopup="true" @closePopup="$emit('closePopup')" />
        <div class="warper">
            <van-form>
                <van-field v-model.trim="form.dismant_batch_number" label="拆解批次编号" placeholder="请输入拆解批次编号" />
                <van-field
                    readonly
                    clickable
                    name="picker"
                    v-model="disassembleTypeIdName"
                    label="拆解类型"
                    placeholder="点击选择拆解类型"
                    @click="showType = true"
                    />
                <van-popup v-model="showType" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="columnsType"
                        value-key="name"
                        @confirm="onConfirm(1,$event)"
                        @cancel="showType = false"
                    />
                </van-popup>

                 <van-field
                    v-if="(form.disassembleTypeId == 2)"
                    readonly
                    clickable
                    name="picker"
                    v-model="purchasingchannels_value"
                    label="拆解单位"
                    placeholder="点击选择拆解单位"
                    @click="showChanne = true"
                    ></van-field>
                <van-popup v-model="showChanne" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="ChanneList"
                    value-key="shopName"
                    @confirm="onConfirm(2,$event)"
                    @cancel="showChanne = false"
                />
                </van-popup>
                <van-field v-model.trim="form.price" v-if="(form.disassembleTypeId == 2)" label="拆解费用" placeholder="请输入拆解费用" />
                <van-field
                    readonly
                    clickable
                    name="picker"
                    v-model="disassemblyMethodValue"
                    label="拆解方式"
                    placeholder="点击选择拆解方式"
                    @click="showChasing = true"
                    />
                <van-popup v-model="showChasing" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="ChasingType"
                        value-key="name"
                        @confirm="onConfirm(3,$event)"
                        @cancel="showChasing = false"
                    >
                    </van-picker>
                </van-popup>
                
                
                <van-field v-if="(form.disassemblyMethodId!=3)" v-model.trim="form.remark" label="拆解备注" placeholder="请输入拆解备注" />
                <van-field
                v-if="(form.disassemblyMethodId!=3)"
                    readonly
                    clickable
                    name="picker"
                    v-model="disassembleUserValue"
                    label="拆解人员"
                    placeholder="点击选择拆解人员"
                    @click="UserChange"
                    />
                <van-popup v-model="showUser" position="bottom" class="userPopup"  :close-on-click-overlay="false"  :style="{height: '100%',}">
                    <van-checkbox-group class="group" v-model="result">
                        <van-checkbox class="checkbox" v-for="item in principalUserList" :key="item.id" :name="item.id">{{item.realName}}</van-checkbox>
                    </van-checkbox-group>
                    <div class="userBtn">
                        <van-button @click="(showUser = false)">取消</van-button>
                        <van-button type="info" @click.stop="userSubmit">确认</van-button>
                    </div>
                </van-popup>
                <van-field
                    v-if="(form.disassemblyMethodId ==3)"
                    readonly
                    clickable
                    name="picker"
                    v-model="working_weidoo_Value"
                    label="绩效统计维度"
                    placeholder="点击选择绩效统计维度"
                    @click="showWei = true"
                    />
                <van-popup v-model="showWei" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="weidooArr"
                        value-key="name"
                        @confirm="onConfirm(4,$event)"
                        @cancel="showWei = false"
                    >
                    </van-picker>
                </van-popup>
                <van-field v-if="(form.disassemblyMethodId ==3)" v-model="value" label="拆解工序" >
                    <template #button>
                        <van-button size="small" type="info" @click="changeGongxu">编辑/选择工序</van-button>
                    </template>
                </van-field>
                <van-field
                    v-if="gongxu"
                    readonly
                    clickable
                    name="picker"
                    v-model="gongxuValue"
                    label="工序"
                    placeholder="点击选择选择工序"
                    @click="showGongxu = true"
                    />
                <van-popup v-model="showGongxu" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="tableData"
                        value-key="title"
                        @confirm="onConfirm(5,$event)"
                        @cancel="showGongxu = false"
                    >
                    </van-picker>
                </van-popup>
                <van-popup v-model="gongxuPopup" position="bottom" class="userPopup"  :close-on-click-overlay="false"  :style="{height: '100%',}">
                    <processPage @closePopup="closePopup" :processObj="processObj" v-if="gongxuPopup" @gongxuHan="gongxuHan" />
                </van-popup>

                <div style="margin: 16px;" v-if="(isyuchuli!=1)">
                    <van-button round block type="info" @click="onSubmit">提交</van-button>
                </div>
                </van-form>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
import {is_post} from '@/Http/api'
import {noPageList,dismantleAdd,flowGetList} from '@/api/publics'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import processPage from './processPage'
    export default {
        props:['isyuchuli'],
        components: {
            Head, processPage
        },
        data() {
            return {
                value:"",
                showType: false,//拆解类型弹窗
                showChanne: false,//单位
                showChasing: false,//方式
                showWei: false,//纬度
                showUser: false,//个人
                showGongxu: false,//工序
                gongxuPopup: false,//工序
                columnsType:[
                    {name:'自拆解',id:1},
                    {name:'他人拆解',id:2},
                ],
                ChasingType:[
                    {name:'快捷精拆',id:1},
                    {name:'快捷粗拆',id:2},
                    {name:'按工序拆解',id:3},
                ],
                form:{
                    dismant_batch_number:"",//拆解批次号
                    disassembleTypeId:"",//拆解类型
                    purchasingchannels_id:'',//渠道ID
                    price:"",//拆解费用
                    disassemblyMethodId:"",//拆解方式
                    disassembleUserId:"",//拆解人员
                    working_weidoo_id:"",//纬度
                },
                working_weidoo_Value:"",//纬度
                dismant_batch_number_value:"",//拆解类型汉子
                purchasingchannels_value:"",//渠道值
                disassemblyMethodValue:"",//拆解方式
                disassembleUserValue:"",//人员
                ChanneList:[],//渠道列表
                principalUserList:[],//人员列表
                result:[],
                weidooArr:[],//纬度
                listId:'',
                tableData:[],//
                gongxu:false,
                gongxuValue:'',//选择的工序
                processObj:{},//选择的工序
                arrList:[],//工序任務
            }
        },
        methods: {
            closePopup(){
                this.gongxuPopup = false
            },
            gongxuHan(arr){
                console.log(arr)
                this.arrList = arr
                this.gongxuPopup = false
            },
            onConfirm(num,val) {
                if(num == 1) {
                    this.form.disassembleTypeId = val.id
                    this.disassembleTypeIdName = val.name
                    this.showType = false
                    if(val.id == 2) this.getPurchasing()
                }else if(num == 2) {
                    this.form.purchasingchannels_id = val.id
                    this.purchasingchannels_value = val.shopName
                    this.showChanne = false
                }else if(num == 3) {
                    this.form.disassemblyMethodId = val.id
                    this.disassemblyMethodValue = val.name
                    this.showChasing = false
                    if(val.id == 3){
                        this.getnoPageList()
                    }
                }else if(num == 4) {
                    this.form.working_weidoo_id = val.id
                    this.working_weidoo_Value = val.name
                    this.showWei = false
                }else if(num == 5) {
                    // this.gongxu = false
                    this.showGongxu = false
                    this.gongxuPopup = true
                    this.processObj = val
                    this.gongxuValue = val.title
                }
            },
            async getnoPageList(){
                try{
                    const {data} = await noPageList().then(res=>res)
                    this.weidooArr = data.data
                }catch{}
               
            },
            //选择工序  
            async changeGongxu(){
                try{
                    const {data} = await flowGetList().then(res=>res)
                    this.tableData = data.data
                    this.gongxu = true
                }catch{

                }
               
            },
             //获取采购渠道
             getPurchasing(){
                this.$http.post('index.php/index/Purchasing_Channels/purchasingChannelsList2').then(res=>{
                    this.ChanneList = res.data.data
                })
            },
             //获取人员
             getUserList(){
                this.$http.post('/index.php/index/user/userList',{ type: 'bulkBreaker' }).then(res=>{
                    this.principalUserList = res.data.data
                })
            },
            //提交
            onSubmit(){
                if(this.form.disassemblyMethodId == 3){
                    //工序拆解
                    this.setgongxu()
                }else{
                    //非工序拆解
                    this.setchai()
                }
            },
             //工序拆解
            async setgongxu(){
                let obj = {
                    data: this.arrList,
                    end_of_lifevehicles_ids: [this.listId] ,
                    dismant_batch_number: this.form.dismant_batch_number,
                    working_weidoo_id: this.form.working_weidoo_id
                }
                const {data} = await dismantleAdd(obj).then(res=>res)
              
                this.$toast.success(data.msg)
                this.$emit('closeChai')
            },
            //非工序拆解
            setchai(){
                const obj = {
                    'disassembleTypeId': this.form.disassembleTypeId,
                    'disassemblyMethodId': this.form.disassemblyMethodId,
                    'remark': this.form.remark,
                    dismant_batch_number: this.form.dismant_batch_number,
                    price: this.form.disassembleTypeId == 2 ? this.form.price : undefined,
                    purchasingchannels_id: this.form.disassembleTypeId == 2 ?this.form.purchasingchannels_id : undefined,
                    user_json:  this.disassembleUserId,
                    ids:  [this.listId] 
                }
                console.log(this.listId)
                let data = encipherMent( JSON.stringify(obj))
                let param = new URLSearchParams();
                param.append("value",data);
                is_post('/index.php/index/Disassemble_Allocation/add', param).then(res=>{
                    this.$toast.success(data.msg)
                    this.$emit('closeChai')
                })
            },
            //选择拆解人
            UserChange(){
                this.showUser = true
                this.getUserList()
            },  
            //拆解人
            userSubmit(){
                this.showUser = false
                var arr = []
                var nameArr = []
                this.result.forEach(i=>{
                    this.principalUserList.forEach(j=>{
                        if(i==j.id){
                            const obj = {
                            'realName': j.realName,
                            'id': j.id
                        }
                            arr.push(obj)
                            nameArr.push(j.realName)
                        }
                    })
                })
                this.disassembleUserValue = nameArr.join(',')
                this.disassembleUserId = arr
               
            },  
        },
    }
</script>

<style lang="scss" scoped>
.warper{
    margin-top: 50px;
    .userPopup{
        .userBtn{
            text-align: center;
            margin-top: 10px;
            .van-button{
                margin: 10px;
            }
        }
        
        .group{
            text-align: center;
            padding: 20px;
            height: 80vh;
            overflow-y: scroll;
            border-bottom: 1px solid #eee;
            .checkbox{
                margin: 5px 0;
            }
        }
    }
}
</style>